import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { ReactNode } from 'react';
import 'reflect-metadata';
import Layout from '../layout';
import styled from 'styled-components';
import {  Column, Container, Row, FlexProps } from '../styles/grids';

interface TheaterPageProps {
    children: ReactNode;
}

// TODO: Typify
interface TheaterData {
    allContentfulTheater: {
        edges: any[];
    };
}

const TheaterWorks = styled.div<FlexProps>`
  text-align: center;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #F3867F;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }

  h1 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 30px;
      margin: 5px;
  }
`;

const TheaterPage: React.FC<TheaterPageProps> = () => {

    const { allContentfulTheater }: TheaterData = useStaticQuery(graphql`
        query {
            allContentfulTheater(sort: {fields: date, order: DESC}) {
                edges {
                    node {
                        name
                        date
                        url
                    }
                }
            }
        }
    `);

    const ColumnProps = {
        color: '#F3867F',
        margin: '10px',
        marginBottom: '10vw',
        padding: '20px',
        position: 'relative',
        overflow: 'scroll'
    };


    return (
        <Layout>
            <Container>
                <Row>
                    <Column {...ColumnProps} flex={1}>
                        <TheaterWorks>
                            {allContentfulTheater.edges.map((edge, key) => {
                                return (
                                    <>
                                        <StyledLink to={edge.node.url} target="_blank">
                                            <i><h1 key={key}>{edge.node.name}</h1></i>
                                        </StyledLink>
                                    </>
                                )
                            })}
                        </TheaterWorks>
                    </Column>
                </Row>
            </Container>
        </Layout>
    );
};

export default TheaterPage